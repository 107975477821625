<template>

  <div :key='key'>


    <b-row class='px-2'>
      <b-col lg='12' md='12' class='px-0'>
        <b-card no-body class='wameed-card'>
          <b-card-body
              class='position-relative justify-content-between px-5'>
            <div class='d-flex justify-content-between flex-wrap align-items-center'>

              <div>
                <div class='text-med-18 text-font-main unset-line-height'>
                  {{ $t('products.options.title') }}
                </div>
                <div class='text-reg-16 unset-line-height'>
                  {{ $t('products.options.body') }}
                </div>
              </div>

              <div class='d-flex  flex-wrap  align-items-center' style='gap: 16px'>

                <!--                <div class='mx-2 mt-3 outline-link small rounded transparent'>-->
                <!--                  <add-primary-icon />-->
                <!--                  {{ $t('products.options.from_existing') }}-->
                <!--                </div>-->
                <div class='mx-2 mt-3 outline-link small rounded fill' @click='addOption'>
                  <add-circle-icon />
                  {{ $t('products.options.add_new') }}
                </div>

              </div>

            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class='px-2' v-if='options.length>0' :key='key'>
      <b-col lg='12' md='12' class='px-0'>
        <b-card no-body class='wameed-card'>
          <b-card-header class='align-items-start tab-title mb-4'>
            <h5 class='text-med-20 text-font-main mb-0'>
              {{ $t('products.options.title') }}
            </h5>
          </b-card-header>

          <b-card-body
              class='position-relative justify-content-between px-5'>

            <div
                v-for='(option, index) in options'
                :id='option.id'
                :key='index'
            >


              <div class='d-flex flex-wrap'>


                <b-col cols='12' md='4'>
                  <text-input
                      :id="'option-name' + index"
                      v-model='option.name'
                      :label="$t('products.form.option.name')"
                      rules='required'
                      :placeholder="$t('form.text.placeholder')"
                  />
                </b-col>
                <b-col cols='12' md='4'>
                  <div class='text-font-main mb-4'>
                    {{ $t('products.form.option.importance') }}
                  </div>
                  <div class='d-flex mb-5' style='gap:16px'>
                    <button type='button' class='discount_on_btn'
                            style='width: 50%; padding: 10px'
                            :class="[0 === option.type?'active':'']"
                            @click='changeOptionImportance(index,0)'
                    >
                      {{ $t('common.optional') }}
                    </button>
                    <button type='button' class='discount_on_btn'
                            style='width: 50%; padding: 10px'
                            :class="[1 === option.type?'active':'']"
                            @click='changeOptionImportance(index,1)'
                    >
                      {{ $t('common.required') }}
                    </button>
                  </div>

                </b-col>
                <b-col cols='12' md='4'>
                  <wameed-dropdown
                      v-if='option.type==1'
                      :id="'option-pricing' + index"
                      v-model='option.pricingObject'
                      :label="$t('products.form.option.pricing')"
                      :placeholder="$t('products.form.option.pricing')"
                      variant='disable'
                      :items='pricingTypes'
                      class='wameed_dropdown'
                      title='name'
                      rules='required'
                      @input='(item)=>changePrice(item,index)'
                      :no_options="$t('common.no_options')"
                  />

                </b-col>

              </div>

              <div class='d-flex flex-wrap' v-if='option.type==0'>

                <b-col cols='12' md='4'>
                  <TextInput
                      :id="'option-min' + index"
                      v-model='option.min'
                      rules='required'
                      field-classes='w-100'
                      :label="$t('products.form.option.min')"
                      :placeholder="$t('form.text.placeholder')"
                      type='number'
                  />
                </b-col>
                <b-col cols='12' md='4'>
                  <TextInput
                      :id="'option-max' + index"
                      v-model='option.max'
                      rules='required'
                      field-classes='w-100'
                      :label="$t('products.form.option.max')"
                      :placeholder="$t('form.text.placeholder')"
                      type='number'
                  />
                </b-col>
              </div>


              <div>
                <div class='text-font-main mb-4 px-4'>
                  {{ $t('products.form.option.importance') }}
                </div>
                <div class='product-inner-section'

                >

                  <div class='d-flex flex-wrap'

                       v-for='(innerOption, innerIndex) in option.inner_options'
                       :id='innerOption.id'
                       :key='innerIndex'

                  >
                    <b-col cols='12' md='4'>
                      <text-input
                          :id="'inner-name' + index + innerIndex"
                          v-model='innerOption.name'
                          :label="$t('products.form.option.inner_options.title')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols='12' md='4'>
                      <TextInput
                          :id="'inner-price' + index + innerIndex"
                          v-model='innerOption.price'
                          rules='required'
                          field-classes='w-100'
                          :label="$t('products.form.option.inner_options.new_price')"
                          :placeholder="$t('form.text.placeholder')"
                          type='number'
                      />
                    </b-col>
                    <div v-if='innerIndex>1'>
                      <button class='remove-icon' @click='removeInnerOption(index,innerIndex)'>
                        <trash-icon />
                        {{ $t('products.form.option.inner_options.delete') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class='d-flex'>

                <div class='mx-2 mt-3 outline-link small rounded fill-gray' @click='addInnerOption(index)'>
                  <add-gray-icon />
                  {{ $t('products.options.add_new') }}
                </div>
              </div>
              <div class='my-5'>

                <hr v-if='index<options.length-1'>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
  <!-- SECOND REPEATER - START -->
  <!-- SECOND REPEATER - END -->
</template>

<script>
import { WameedDropdown, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { ProductGroupPricingTranslations } from '@/enums/ProductGroupPricing.enum';

export default {
  name: 'additionalData',
  components: {
    TextInput, WameedDropdown
  },
  props: {
    type: String,
    btnText: String,
    values: Array
  },
  data() {
    return {
      key: 0,
      pricingTypes: ProductGroupPricingTranslations,
      counter: 0,
      changePriceOptionIndex: null,
      options: []
    };
  },
  computed: {
    getOptions() {
      let options = this.pricingTypes;
      if (this.options.some((option) => option.pricing === 1)) {
        options = options.filter((item) => item.id !== 1);
      }
      return options;
    }
  },
  methods: {
    changeOptionImportance(index, type) {
      this.options[index].type = type;
      // remove the pricing
      if (this.options[index].pricing === 1) {

        this.changePriceOptionIndex = null;
      }
      this.options[index].pricing = null;
      this.options[index].pricingObject = null;
      this.key += 1;

    },
    changePrice(item, index) {
      if (this.options.some((option) => option.pricing === 1) && item.id === 1) {
        alert(this.$t('products.options.error'));
        this.options[index].pricingObject = null;
        this.options[index].pricing = null;
      } else {

        this.options[index].pricingObject = item;
        this.options[index].pricing = item.id;
      }
      this.key += 1;
    },
    addOption() {
      this.options.push({
        id: 0,
        inner_options: [{}, {}]
      });
      this.counter -= 1;
    },
    addInnerOption(index) {
      if (!this.options[index].inner_options) this.options[index].inner_options = [];
      this.options[index].inner_options.push({
        id: 0
      });
      this.key += 1;
    },

    removeInnerOption(index, optionIndex) {
      if (this.options[index].inner_options.length > 1)
        this.options[index].inner_options.splice(optionIndex, 1);
    }


  },
  created() {
    this.options = this.values;
    this.options.forEach((option) => {
      option.pricingObject = this.pricingTypes.find((item) => item.id === option.pricing);
    });
  },
  watch: {
    values(values) {
      this.options = values;
    }
  }

};
</script>

